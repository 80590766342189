import { EditorSDK } from '@wix/platform-editor-sdk';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { EcomComponent, addComponents } from '@wix/ecom-platform-sdk';
import { removeBookCheckoutPageOnEcom } from '../pages-panel-actions';
import { addBookingCheckoutPage } from '../pages-actions';
import {
  ecomRolloutButNotSite,
  getBookingsData,
  isEcomCartInstalled,
  isEcomInstalled,
} from '../editor-sdk-actions';
import { EditorScriptApi } from '../../api/api';
import { isExperimentEnabled } from '../experiments';
import { experiments, FedopsInterations } from '../../constants';
import {
  updateIsCartEnabledBusinessProperty,
  getIsCartEnabledBusinessProperty,
} from '../migrate-actions';
import {
  openCartSuccessfullyInstalledModal,
  openEcomMigrationProposalModal,
  openEcomHistoryBlockModal,
} from '../migration-modals';
import { migrateAction, MigrateAction } from '../../migration';
import { Translations } from '../translations';

interface SkipEcomInstallationParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken?: string;
  locale?: any;
  editorTranslation: Translations;
  fedopsLogger: IFedOpsLogger;
  migrationActionInput?: MigrateAction;
}

interface RequiredEcomComponentsParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken: string;
}

interface MigrateCartParams {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  instance: string;
}

export const markAsEcom = async ({
  isEcomReady,
  editorScriptApi,
  editorSdk,
  appToken,
}): Promise<boolean> => {
  if (!isEcomReady) {
    const isReady = await editorScriptApi.setEcomCompatible();
    if (isReady) {
      const isEcomMigrationEnabled = await isExperimentEnabled(
        experiments.IS_ECOM_MIGRATION_ENABLED,
      );

      if (!isEcomMigrationEnabled) {
        await removeBookCheckoutPageOnEcom(
          editorSdk,
          appToken,
          editorScriptApi,
        );
      }
    } else {
      await addBookingCheckoutPage(editorSdk, appToken);
    }
    return isReady;
  }
};

export const skipEcomInstallationAction = ({
  isFirstInstall,
  _editorSDK,
  editorScriptApi,
  appToken,
  locale,
  editorTranslation,
  fedopsLogger,
  migrationActionInput,
}: SkipEcomInstallationParams) => {
  return async () => {
    const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);
    const isToggleCartOnByDefaultEnabledFT = await isExperimentEnabled(
      experiments.TOGGLE_CART_ON_BY_DEFAULT,
    );
    const isEcomMigrationEnabled = await isExperimentEnabled(
      experiments.IS_ECOM_MIGRATION_ENABLED,
    );
    const isEcomMigrationProposalModal = await isExperimentEnabled(
      experiments.ECOM_MIGRATION_MODAL_ENABLED,
    );
    const isEcomRevokeWorkaroundEnabled = await isExperimentEnabled(
      experiments.ECOM_REVOKE_WORKAROUND,
    );

    if (isEcomMigrationEnabled) {
      return true;
    }

    if (!isFirstInstall) {
      const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
        _editorSDK,
        editorScriptApi,
        appToken!,
      );

      if (isEcomRolloutButNotSite && migrationActionInput) {
        try {
          fedopsLogger.interactionStarted(FedopsInterations.FixSite);
          await migrateAction(migrationActionInput);
          fedopsLogger.interactionEnded(FedopsInterations.FixSite);
        } catch (e) {
          openEcomHistoryBlockModal(
            _editorSDK,
            editorScriptApi,
            locale,
            migrationActionInput.instance,
          );
        }
        return true;
      }

      if (migrationActionInput && isEcomMigrationProposalModal) {
        if (
          !(await editorScriptApi.getIsBookingsOnEcom()) &&
          (await editorScriptApi.shouldMigrateEcom())
        ) {
          const { instance } = await getBookingsData(_editorSDK, appToken);
          openEcomMigrationProposalModal(
            _editorSDK,
            editorScriptApi,
            locale,
            fedopsLogger,
            instance,
            editorTranslation,
            { ...migrationActionInput, isSelfMigration: true },
          );
          return true;
        }
      }
    }

    if (isCartEnabledFT) {
      const { instance } = await getBookingsData(_editorSDK, appToken);
      const isCartEnabled: boolean | undefined =
        await getIsCartEnabledBusinessProperty(editorScriptApi, instance);

      if (isFirstInstall) {
        // undefined meaning this flag never initiate and we should initiate for first install to true
        if (isCartEnabled === undefined) {
          await updateIsCartEnabledBusinessProperty(
            editorScriptApi,
            instance,
            isToggleCartOnByDefaultEnabledFT,
          );
        }
        return false;
      }

      if (
        isEcomRevokeWorkaroundEnabled &&
        !(await isEcomInstalled(_editorSDK)) &&
        (await editorScriptApi.getIsBookingsOnEcom())
      ) {
        return false;
      }
      if (!isCartEnabled) {
        return true;
      }

      const isCartinstalled = !!(await isEcomCartInstalled(_editorSDK));

      if (!isCartinstalled) {
        await openCartSuccessfullyInstalledModal(
          _editorSDK,
          editorScriptApi,
          locale,
          instance,
        );
      }

      return isCartinstalled;
    }

    if (isFirstInstall) {
      return false;
    }

    return !(await editorScriptApi.canInstallEcom());
  };
};

export const requiredEcomComponentsAction = ({
  isFirstInstall,
  _editorSDK,
  editorScriptApi,
  appToken,
}: RequiredEcomComponentsParams): (() => Promise<EcomComponent[]>) => {
  return async () => {
    const isCartEnabled = await isExperimentEnabled(experiments.CART_ENABLED);
    const isInstallCartIconEnabled = await isExperimentEnabled(
      experiments.INSTALL_CART_ICON_ENABLED,
    );
    const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();

    if (!isFirstInstall) {
      const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
        _editorSDK,
        editorScriptApi,
        appToken,
      );

      if (isEcomRolloutButNotSite) {
        return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
      }
    }

    if (isCartEnabled && (isFirstInstall || isBookingRollout)) {
      if (isInstallCartIconEnabled) {
        return [
          EcomComponent.CHECKOUT,
          EcomComponent.THANK_YOU_PAGE,
          EcomComponent.CART,
          EcomComponent.CART_ICON,
        ];
      }
      return [
        EcomComponent.CHECKOUT,
        EcomComponent.THANK_YOU_PAGE,
        EcomComponent.CART,
      ];
    }
    return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
  };
};

export const migrateCart = async ({
  editorSdk,
  editorScriptApi,
  instance,
}: MigrateCartParams) => {
  const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();

  if (!isBookingRollout) {
    throw new Error('migrateCart - Site are not Rollout');
  }

  try {
    await updateIsCartEnabledBusinessProperty(editorScriptApi, instance, true);
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } | migrateCart - updateIsCartEnabledBusinessProperty - errorCode: 8ad89f0b-96c9-4538-9158-329418318132`;
    throw new Error(errorMessage);
  }

  const isCartInstalled = !!(await isEcomCartInstalled(editorSdk));

  if (!isCartInstalled) {
    try {
      await addComponents(editorSdk, [EcomComponent.CART]);
    } catch (e) {
      const errorMessage = `${
        e?.message ? e.message : JSON.stringify(e)
      } | migrateCart - addComponents - errorCode: 4589f0b-96c9-4538-9158-329418398312`;
      throw new Error(errorMessage);
    }
  }

  console.log('Cart Migration Success');
};
